document.addEventListener('DOMContentLoaded', () => {
    
    const btn = document.querySelectorAll('.js-lessmore');

    const init = function() {
        for (let i = 0; i < btn.length; i++) {
            btn[i].addEventListener('click', (e) => {
                
                e.currentTarget.closest(".o-cols__item").classList.toggle('show-all')
                
                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
                
/*
                let target = document.querySelector(e.currentTarget.dataset.el);
                    target.classList.add('all-items');
                    btn[i].style.display = 'none';
*/
            })
        }
    };

    btn.length > 0 ? init() : false;
    
}, false);