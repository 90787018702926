import css from './sass/style.scss';

require('./js/accordion')
require('./js/anims')
require('./js/carousels')
require('./js/custom-select')
require('./js/fonts')
require('./js/init')
//require('./js/inview')
require('./js/lessmore')
require('./js/lottie')
require('./js/nav')
require('./js/polyfills')
require('./js/scrollpos')
require('./js/sticker')
require('./js/smoothscroll')
