import { gsap, Elastic, Power1, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {

    const el_it = document.querySelectorAll('.js-it_accordion');
    
    const init_it = (obj) => {
        let item = obj.querySelectorAll('.js-item'),
            tl;
  
        const toggle = function(ev, section, op) {
            tl = gsap.timeline({clearProps:"all"});
            
            tl.to(section, {
                height: ev,
                duration: .25,
                ease: Power1.easeOut
            })
            .fromTo(section, .5, {
                opacity: 0,
                y: 20,
                ease: 'elastic.out(1, .8)',
            }, {
                opacity: 1,
                y: 0,
                ease: 'elastic.out(1, .8)',
            })
        }

        const action = (e) => {
            const _that = e.currentTarget,
                  _height = 0;
                        
            if (_that.classList.contains('is-rollout')) {
                _that.classList.remove('is-rollout');
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);                
                
            } else {
                for (let i = 0; i < item.length; i ++) {
                    item[i].classList.remove('is-rollout');
                    toggle(_height, item[i].getElementsByClassName('js-section')[0], 0);
                }   
    
                _that.classList.add('is-rollout');
                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
            }
        };

        const start = () => {                
            for( let i = 0; i < item.length; i ++ ) {    
                item[i].addEventListener('click', action);
                if (item[i].classList.contains('is-rollout')) {
                    toggle('auto', item[i].getElementsByClassName('js-section')[0], 1);
                }
            }        
        }
        
        const destroy = () => {
            tl.kill(1,1);

            for( let i = 0; i < item.length; i ++ ) {    
                item[i].removeEventListener('click', action);
                item[i].querySelector('.js-section').removeAttribute('style')
            }
        }
        
        start();
    };
    
    if (el_it.length > 0) {
        for (let i = 0; i < el_it.length; i++) {
            init_it(el_it[i]);
        }
    }

}, false);