import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {

    const cover = document.getElementById('cover');
    
    const init = () => {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(() => {
            window.carousels();
            cover.remove();
            window.anims();
            console.log('deply')
        }, 250);  
        
        window.location.hash ? window.runScroll( window.location.hash ) : false;
    };
    
    if (Cookies.get('wysk-nosticker')) { 
        document.documentElement.classList.add('no-sticker');
        document.querySelector('.js-sticker') ? document.querySelector('.js-sticker').style.display = 'none' : false;
    }
    
    window.addEventListener('load', init);

}, false);
