const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {
    
    window.runScroll = function(el, o) {
        let offsetTop = document.querySelector(el).offsetTop,
            topbar = document.querySelector('.js-topbar'),
            nav = document.querySelector('.js-nav');

        o === undefined ? o = 0 : false;

        if (nav) {
            nav.classList.contains('is-visible') ? window.hideMenu() : false;
        }
        
        window.scrollTo({
            behavior: 'smooth',
            top:
              document.querySelector(el).getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              o - document.querySelector('.js-sticker').clientHeight,
        }) 
        
        //document.querySelector('.c-topbar__main').clientHeight - 
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = function(e) {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;            

            //let url = location.protocol + "//" + document.domain + "/" + location.pathname.split('/')[1];
            //let url = '/';
            let url = e.currentTarget.href;
            
            document.querySelector(target) ? window.runScroll(target, offset) :
                window.open(url + target, '_self');
                
            

/*
            document.getElementById(target.slice(1, target.length)) ? window.runScroll(target, offset) :
                window.open(url + target, '_self');
*/
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
