import Cookies from 'js-cookie';
import { gsap, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {	
	const el = document.querySelector('.js-sticker');
    const init = () => {
        el.querySelector('.js-sticker-close').addEventListener('click', () => {
            gsap.to(el, {
                height: 0,
                duration: .25,
                ease: Power1.easeOut
            })
            Cookies.set('wysk-nosticker');
        })
    };
    el ? init() : false;
}, false);
