import "@lottiefiles/lottie-player";
import anim1 from '../img/howwework/menu-01.json';
import anim2 from '../img/howwework/menu-02.json';
import anim3 from '../img/howwework/menu-03.json';

document.addEventListener('DOMContentLoaded', () => {
    let player = document.querySelectorAll(".js-lottie");

    if (player.length > 0) {
        let anims = [anim1, anim2, anim3];
        for (let i = 0; i < player.length; i++) {
            player[i].load(anims[i]);
            player[i].addEventListener("ready", () => {
                LottieInteractivity.create({
                    player: player[i],
                    mode: "cursor", 
                    actions: [ 
                        { 
                            type: "hover", 
                            forceFlag: false
                        }
                    ]
                });
            });
        }
    }
    
    let player_mobile = document.querySelectorAll(".js-lottie--mobile");

    if (player_mobile.length > 0) {
        let anims = [anim1, anim2, anim3];
        for (let i = 0; i < player_mobile.length; i++) {
            player_mobile[i].load(anims[i]);
            player_mobile[i].addEventListener("ready", () => {
                
                LottieInteractivity.create({
                    player: player_mobile[i],
                    mode: "scroll", 
                    actions: [
                        
                        {
                            visibility: [0, 1],
                            type: 'seek',
                            frames: [0, 30],
                        },
                        
                    ]
                });
            });
        }
    }

}, false)
