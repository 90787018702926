import { gsap, Elastic, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        const stagger = document.querySelectorAll('.js-stagger');
        if (stagger.length > 0) {
            ScrollTrigger.matchMedia({
                "(min-width: 641px)": () => {
                    const sections = gsap.utils.toArray(".js-stagger");
                    sections.forEach( section => {
                        gsap.from(section.querySelectorAll('.js-item'), {
                            opacity: 0,
                            x: 40,
                            stagger: 0.1,
                            scrollTrigger: {
                                trigger: section,
                                start: "top bottom-=200",
                                toggleActions: "play complete complete reverse",
                            }
                        });
                    });
                }
            })
        }
        
        if (document.querySelectorAll('.a-fadeIn').length > 0) {
            gsap.utils.toArray(".a-fadeIn > *").forEach((section) => {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete reset",
                    },
                    autoAlpha: 0,
                    duration: 1,
                    y: '30px'
                });
            });
        }
    };

}, false)
