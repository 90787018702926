document.addEventListener('DOMContentLoaded', () => {
    
    const el = document.getElementsByClassName('js-topbar')[0];
    const action = function() {
        let scroll_pos = window.pageYOffset || window.scrollY,
            sticker = el.querySelector('.c-topbar__sticker');

        if (sticker) {
            scroll_pos > sticker.clientHeight ? el.classList.add('is-fixed') : el.classList.remove('is-fixed');
        } else {
            scroll_pos > 0 ? el.classList.add('is-fixed') : el.classList.remove('is-fixed');
        }
    }

    action();
    el ? window.addEventListener('scroll', action) : false;

}, false);
