import Swiper, { Autoplay, EffectFade, FreeMode, Mousewheel, Navigation, Thumbs } from 'swiper';
import 'swiper/swiper.scss';
//import 'swiper/modules/effect-creative/effect-creative.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/thumbs/thumbs.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';

document.addEventListener('DOMContentLoaded', () => {
    window.carousels = () => {
        const collections = document.querySelector('.js-collectionsCarousel');
        if (collections) {
            const swiper = new Swiper(collections, {
                modules: [Mousewheel, Navigation],
                loop: true,
                mousewheel: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                spaceBetween: 16,
                slidesPerView: 'auto',
                speed: 500,
            });
        }
        
        const filters = document.querySelector('.js-filtersCarousel');
        if (filters) {
            let swiper,
                mediaQuery = '(max-width: 640px)',
                mediaQueryList = window.matchMedia(mediaQuery);
            
            const start = ()=> {
        	    swiper = new Swiper(filters, {
            	    centeredSlides: true,
                    slidesPerView: 'auto',
                    speed: 500,
                });
            }
    
            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : swiper.destroy();
            })
    
            mediaQueryList.matches ? start() : false;
        };
        
        const partners = document.querySelector('.js-partnersCarousel');
        if (partners) {
            let swiper,
                mediaQuery = '(max-width: 640px)',
                mediaQueryList = window.matchMedia(mediaQuery);
            
            const start = ()=> {
        	    swiper = new Swiper(partners, {
            	    autoplay: {
                        delay: 2000,
                    },
                    loop: true,
                    modules: [Autoplay],
                    slidesPerView: 'auto',
                    speed: 500,
                });
            }
    
            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : swiper.destroy();
            })
    
            mediaQueryList.matches ? start() : false;
        };
        
        const gallery_big = document.querySelector('.js-gallery--big');
        if (gallery_big) {
            const gallery_small = document.querySelector('.js-gallery--small');

            const swiper_small = new Swiper(gallery_small, {
                slidesPerView: 'auto',
                speed: 500,
                spaceBetween: 16,
                watchSlidesProgress: true,
                breakpoints: {
                    769: {
                        direction: 'vertical',
                    }
                }
            });
            
            const swiper_big = new Swiper(gallery_big, {
                modules: [Thumbs],
                speed: 500,
                thumbs: {
                    swiper: swiper_small,
                },
            });
        }
        
        const hero = document.querySelector('.js-heroCarousel');
        if (hero) {
            const swiper = new Swiper(hero, {
                autoplay: {
                    delay: 4500,
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                modules: [Autoplay, EffectFade],
                loop: true,
                speed: 600,
            });

            /*
            const swiper = new Swiper(hero, {
                autoplay: {
                    delay: 5000,
                },
                effect: 'creative',
                    creativeEffect: {
                    prev: {
                        translate: [0, 0, -400],
                    },
                    next: {
                        translate: ['100%', 0, 0],
                    },
                },
                modules: [Autoplay, EffectCreative],
                loop: true,
                speed: 1800,
            });
*/
        }
    }

}, false)
