import { gsap, Power1 } from "gsap";
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const nav = document.querySelector('.js-nav'),
          hamburger = document.querySelector('.js-hamburger'),
          menu = document.querySelector('.js-menu');

    const init = function() {

        window.hideMenu = function() {
            document.getElementsByClassName('js-nav')[0].classList.remove('is-visible');
            document.getElementsByClassName('js-hamburger')[0].classList.remove('is-active');
            
            document.documentElement.classList.remove('menu-opened');
            
            setTimeout(function() {
                document.getElementsByClassName('js-nav')[0].classList.remove('is-block');
                document.getElementsByClassName('js-nav')[0].classList.remove('is-animated');
            }, 400);
            
            enableBodyScroll(document.getElementsByClassName('js-menu')[0]);
        };

        const showMenu = () => {
            
            nav.classList.add('is-block');
            hamburger.classList.add('is-active');
            document.documentElement.classList.add('menu-opened');

            setTimeout(function() {
                nav.classList.add('is-visible');
                showLinks();
            }, 10);
            
            const showLinks = function() {
                const blocks = menu.querySelectorAll("li");
                const tl = gsap.timeline().delay(.1).eventCallback("onComplete", function() {
                          nav.classList.add('is-animated');
                      })

                .from(blocks, {
                    x: 50,
                    opacity: 0,
                    stagger: {
                        each: 0.1
                    }
                });                
            };

            disableBodyScroll(menu);
        };

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };

        hamburger.addEventListener('click', toggleMenu);

        // Hide menu on ESC

        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });      
    };

    nav ? init() : false;

}, false);